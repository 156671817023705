<template>
  <div style="margin-bottom: 0.5rem">
    <date-picker
      class="lp-datepicker"
      style="display: inline-block"
      v-model="date"
      @change="selDateTime"
      :lang="lang"
      type="datetime"
      format="YYYY-MM-DD hh:mm"
      :minute-step="10"
      v-if="type==='datetime'">
    </date-picker>

    <date-picker
      class="lp-datepicker"
      style="display: inline-block"
      v-model="date"
      :lang="lang"
      @change="selDateRange"
      range
      v-else-if="type==='daterange'"></date-picker>

    <date-picker
      class="lp-datepicker"
      style="display: inline-block"
      v-model="date"
      :lang="lang"
      @change="selDateTimeRange"
      range
      type="datetime"
      format="YYYY-MM-DD HH:mm:ss"
      v-else-if="type==='datetimerange'"></date-picker>

    <date-picker
      class="lp-datepicker"
      style="display: inline-block"
      v-model="date"
      @change="selDate"
      :lang="lang"
      format="YYYY-MM-DD"
      :minute-step="10"
      v-else>
    </date-picker>

  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props:['data', 'type'],

    data(){
      return {
        date:'',
        lang: {
          days: ['일', '월', '화', '수', '목', '금', '토'],
          months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
          pickers: ['7일다음', '30일다음', '7일이전', '30일이전'],
          placeholder: {
            date: '날짜 선택',
            dateRange: '기간 선택'
          }
        }
      }
    },
    created() {
      if ( this.data ) {
        this.date = this.data;
      }
    },
    watch:{
      data(n){
        if(n){
          this.date = n;
          if(n.indexOf('1111-01-01')>-1){
            this.date = '';
          }
          if(n.indexOf('9999-01-01')>-1){
            this.date = '';
          }
        }
        /*else{
          this.date = new Date();
        }*/
      }
    },
    methods: {
      selDateRange(date){
        let date_range = [];
        date_range.push(moment(date[0]).format('YYYY-MM-DD'));
        date_range.push(moment(date[1]).format('YYYY-MM-DD'));
        this.$emit('setDate', date_range);
      },
      selDate(date){
        this.$emit('setDate', moment(date).format('YYYY-MM-DD'));
      },
      selDateTime(date){
        this.$emit('setDate', moment(date).format('YYYY-MM-DD hh:mm'));
      },
      selDateTimeRange(date){
        let date_time_range = [];
        date_time_range.push(moment(date[0]).format('YYYY-MM-DD hh:mm'));
        date_time_range.push(moment(date[1]).format('YYYY-MM-DD hh:mm'));
        this.$emit('setDate', date_time_range);
      }
    }
  }
</script>

<style>

  .lp-dating {
    display:inline-block;
    margin: 0 8px;
    font-size: 18px;
    color:#064160;
  }

  .content ul {
    list-style: unset;
    margin-left: 0;
    margin-top: 0;
  }

  .mx-input {
    height: 31.5px;
  }
</style>
