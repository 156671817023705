import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer: (state) => ({
        user: state.user,
        hidePopup: state.hidePopup,
        exposuredPopup: state.exposuredPopup,
        recmCode: state.recmCode,
        prevPath: state.prevPath,
        survey: state.survey,
        trial: state.trial,
        basket: state.basket,
      }),
    }),
  ],
  state: {
    device: 'pc',
    user: {
      token: '',
      user_id: 0,
    },
    admin: {
      token: '',
      id: 0,
    },
    basket: {},
    openLogin: false,
    // 로딩 상태값
    loadingStatus: true,
    loading: '',
    prevPath: '',
    accountDone: undefined,
    temporaryService: undefined,
    hidePopup: undefined, // 팝업 숨길 날짜
    exposuredPopup: false, // 팝업 노출여부 (이미 노출되었으면 숨기기 위함)
    userService: undefined, // 앱마켓 용. iframe으로 앱마켓 호출하는 서비스 정보
    recmCode: '',
    survey: undefined,
    trial: false,
  },
  mutations: {
    setSurvey(state, value) {
      state.survey = value;
    },
    setTrial(state, value) {
      state.trial = value;
    },
    setRecmCode(state, value) {
      state.recmCode = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setAdmin(state, value) {
      state.admin = value;
    },
    setBasket(state, value) {
      state.basket = value;
    },
    setDevice(state, value) {
      state.device = value;
    },
    logOut(state) {
      state.user = {
        token: '',
        user_id: 0,
      };
      state.basket = {};
      state.trial = false;
    },
    setOpenLogin(state, value) {
      state.openLogin = value;
    },
    setLoading(state, value) {
      state.loadingStatus = value;
    },
    setLoadingText(state, value) {
      state.loading = value;
    },
    clearLoading(state) {
      state.loading = '';
    },
    setPrevPath(state, value) {
      state.prevPath = value;
    },
    clearBasket(state) {
      state.basket = {};
    },
    setAccountDone(state, value) {
      state.accountDone = value;
    },
    setTemporaryService(state, value) {
      state.temporaryService = value;
    },
    setHidePopup(state, value) {
      state.hidePopup = value;
    },
    setExposuredPopup(state, value) {
      state.exposuredPopup = value;
    },
    setUserService(state, value) {
      state.userService = value;
    },
  },
  getters: {
    isLogin: (state) => state.user.user_id !== 0,
    user: (state) => state.user,
    admin: (state) => state.admin,
    basket: (state) => state.basket,
    openLogin: (state) => state.openLogin,
    trial: (state) => state.trial,
    loading: (state) => state.loadingStatus,
    prevPath: (state) => state.prevPath,
    loadingText: (state) => state.loading,
    accountDone: (state) => state.accountDone,
    temporaryService: (state) => state.temporaryService,
    hidePopup: (state) => state.hidePopup,
    exposuredPopup: (state) => state.exposuredPopup,
    userService: (state) => state.userService,
    recmCode: (state) => state.recmCode,
    survey: (state) => state.survey,
  },
});
