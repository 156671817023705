// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import axios from 'axios'
import VueGtag from 'vue-gtag'

// require(`./assets/css/bulma.min.css`)
require(`./assets/css/suneditor.min.css`)
require(`./assets/css/slick.css`)
require(`./assets/css/lp_main.styl`)
import {currency, currencyNum} from './filter.js'
import AdminDatePicker from '../src/components/module/AdminDatePicker'
Vue.component('AdminDatePicker', AdminDatePicker)

import DatePicker from 'vue2-datepicker'
Vue.component('DatePicker', DatePicker)

Vue.config.productionTip = false
Vue.prototype.$axios = axios

import uuidv4 from 'uuid/dist/v4'
Vue.prototype.uuidv4 = uuidv4;

Vue.filter('currency', currency)
Vue.filter('currencyNum', currencyNum)


Array.prototype.remove = function(from, to) {
  let rest = this.slice((to || from) + 1 || this.length);
  this.length = from < 0 ? this.length + from : from;
  return this.push.apply(this, rest);
}

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import 'dayjs/locale/es' // load on demand
dayjs.locale('ko')

Vue.prototype.dayjs = dayjs


Vue.mixin({
  activated() {
    // 이전 페이지에서 push로 들어온 경우, 현재 경로가 alivePage 목록에 있는 경우 getData, init 함수 호출
    if(this.$route.meta.isRefresh) {
      if (this.init) {
        this.init();
      }
      if (this.getData) {
        this.getData();
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isMobile() {
      if(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) return true;
      else return false;
    },
    isIE() {
      if (navigator.userAgent.indexOf('Trident') > -1) return true;
      else return false;
    },
    backendUrl() {
      if (process.env.NODE_ENV === 'production') {
        return 'https://master-dev.launchpack.co.kr:1111'
      } else {
        return 'http://localhost:8001'
        //return 'https://master-dev.launchpack.co.kr:1111'
      }
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
    userService() {
      return this.$store.getters.userService;
    }
  },
  created() {
    this.$axios.defaults.baseURL = this.backendUrl
  },
  methods: {
    findItem(item, key, val) {
      return item === undefined ? undefined : item.find(i => i[key] === val);
    },
    loginRequired() {
      if(this.user.user_id===0) {
        this.$router.replace('/home');
      }
    },
    setGa(category, action, label, value) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
        value: value
      });
    },
    setLoading(msg) {
      this.$store.commit('setLoadingText', msg);
    },
    clearLoading() {
      this.$store.commit('clearLoading');
    },
    imgField(img) {
      return 'background-image: url('+this.image(img)+');' +
        'background-size: cover;' +
        ' background-position: center';
    },
    image(img) {
      if (!img || img === null || img===undefined) return '';
      if (img.search('http') > -1 && img.search('base64,') > -1)
        return this.backendUrl + img;
      else
        return img;
    },
    toast(msg) {
      this.$root.$emit('toast', msg);
    },
    // 전화번호 자동 하이픈
    autoHyphenPhone(str){
      str = str.replace(/[^0-9]/g, '');
      let tmp = '';

      if(str.substr(0,2) === '02') {
        if (str.length < 3) {
          return str;
        } else if (str.length < 6) {
          tmp += str.substr(0, 2);
          tmp += '-';
          tmp += str.substr(2);
          return tmp;
        } else if (str.length < 10) {
          tmp += str.substr(0, 2);
          tmp += '-';
          tmp += str.substr(2, 3);
          tmp += '-';
          tmp += str.substr(5);
          return tmp;
        } else {
          tmp += str.substr(0, 2);
          tmp += '-';
          tmp += str.substr(2, 4);
          tmp += '-';
          tmp += str.substr(6);
          return tmp;
        }
      }
      else {
        if (str.length < 4) {
          return str;
        } else if (str.length < 7) {
          tmp += str.substr(0, 3);
          tmp += '-';
          tmp += str.substr(3);
          return tmp;
        } else if (str.length < 11) {
          tmp += str.substr(0, 3);
          tmp += '-';
          tmp += str.substr(3, 3);
          tmp += '-';
          tmp += str.substr(6);
          return tmp;
        } else {
          tmp += str.substr(0, 3);
          tmp += '-';
          tmp += str.substr(3, 4);
          tmp += '-';
          tmp += str.substr(7);
          return tmp;
        }
      }
    },
    logout() {
      this.$store.commit('logOut')
    },
    checkValidation(value, type) {
      if (!value) return false

      if (type === 'email') {
        // eslint-disable-next-line no-useless-escape
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }
    },
    checkPass(value,type){
      if(!value) return false;
      if(type==='password'){
        let reg = /^[a-zA-Z0-9\d#?!@$%^&*]{0,7}$/;
        return(reg.test(value));
      }
    },
    setAuthHeader() {
      this.$axios.defaults.headers.common['Authorization'] = 'Token ' + this.$store.getters.user.token;
      this.$axios.post(`user/${this.user.user_id}/fcm`, { token: this.$store.getters.fcmToken });
    },
    cloneItem(obj) {
      if (obj === null || typeof(obj) !== 'object')
        return obj;
      let copy = obj.constructor();
      for (let attr in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.cloneItem(obj[attr]);
        }
      }

      return copy;
    },
    datesFormat(dt, type) {
      if(!dt) return '';

      if(type === 'ago') {
        return dayjs(dt).fromNow();
      }

      let format = '';
      switch (type) {
        case 'date_time_1':
          format = 'YY년 MM월 DD일(dd) HH시 mm분'
          break;
        case 'date_time_2':
          format = 'YYYY-MM-DD HH:mm'
          break;
        case 'date_time_3':
          format = 'YYYY.MM.DD HH:mm'
          break;
        case 'date_1':
          format = 'YY년 MM월 DD일(dd)'
          break;
        case 'date_2':
          format = 'YY년 MM월 DD일'
          break;
        case 'date_3':
          format = 'YYYY.MM.DD'
          break;
        case 'date_4':
          format = 'YYYY-MM-DD'
          break;
        case 'date_5':
          format = 'MM월 DD일(dd)'
          break;
        case 'date_6':
          format = 'MM.DD(dd)'
          break;
        case 'date_7':
          format = 'MM월 DD일'
          break;
        case 'date_8':
          format = 'YYYY년 MM월 DD일 dddd'
          break;
        case 'time_1':
          format = 'HH시 mm분'
          break;
        case 'time_2':
          format = 'a hh:mm';
          break;
        case 'year_1':
          format = 'YYYY'
          break;
        case 'month_1':
          format = 'MM'
          break;
        case 'day_1':
          format = 'DD'
          break;
      }
      let day = dayjs(dt).format(format);

      [
        { eng: 'am ', kor : '오전 '},
        { eng: 'pm ', kor : '오후 '},
        { eng: 'Sunday', kor : '일요일'},
        { eng: 'Monday', kor : '월요일'},
        { eng: 'Tuesday', kor : '화요일'},
        { eng: 'Wednesday', kor : '수요일'},
        { eng: 'Thursday', kor : '목요일'},
        { eng: 'Friday', kor : '금요일'},
        { eng: 'Saturday', kor : '토요일'},
      ].forEach(item => {
        day = day.replace(item.eng, item.kor);
      });

      return day;
    },
    routerPush(path, disableRouteGa) {
      if(!disableRouteGa) {
        this.routeGa(path);
      }
      if(this.$route.meta) {
        this.$route.meta.clickPush = true;
      }
      this.$router.push(path);
    },
    routeGa(path, category, label, action) {
      let list = [
        {
          path: '/home',
          label: '홈'
        },
        {
          path: '/quickbuilder',
          label: '퀵빌더'
        },
        {
          path: '/info?id=0',
          label: '주문 제작 소개'
        },
        {
          path: '/info?id=1',
          label: '디자인 마켓 소개'
        },
        {
          path: '/info?id=2',
          label: '퀵빌더 소개'
        },
        {
          path: '/info?id=3',
          label: '플랫폼 운영 솔루션 API 소개'
        },
        {
          path: '/store',
          label: '디자인 마켓'
        },
        {
          path: '/service_inquiry_reg',
          label: '주문 제작 견적 신청'
        },
        {
          path: '/signin',
          label: '로그인'
        },
        {
          path:'/signup',
          label: '회원가입'
        },
        {
          path:'/signemail',
          label: '이메일 인증'
        },
        {
          path:'/findpw',
          label: '비밀번호 찾기'
        },
        {
          path: '/pg',
          label: '결제'
        },
        {
          path:'/store/detail',
          label: '디자인 마켓'
        },
        {
          path: '/solution_info',
          label: '플랫폼 운영 솔루션'
        },
        {
          path: '/order',
          label: '서비스 개설 신청'
        },
        {
          path: '/service_account',
          label: '결제하기'
        },
        {
          path: '/service_account_done',
          label: '서비스 개설 신청 완료'
        },
        {
          path: '/billing',
          label: '빌링등록'
        },
        {
          path: '/bill_done',
          label: '빌링등록완료'
        },
        {
          path: '/verify',
          label: '가입 인증 완료'
        },
        {
          path: '/notice',
          label: '공지사항'
        },
        {
          path: '/notice/detail',
          label: '공지사항 상세'
        },
        {
          path: '/faq',
          label: '자주 묻는 질문'
        },
        {
          path: '/inquiry',
          label: '1:1 문의 목록'
        },
        {
          path: '/inquiry_reg',
          label: '1:1 문의 등록'
        },
        {
          path: '/inquiry/detail',
          label: '1:1 문의 상세'
        },
        {
          path: '/service_inquiry_guide',
          label: '주문 제작 이용 가이드'
        },
        {
          path: '/api_guide',
          label: '플랫폼 운영 솔루션 API 이용 가이드'
        },
        {
          path: '/service_inquiry_reg',
          label: '주문제작 견적 신청'
        },
        {
          path : '/solution_api_reg',
          label: 'Solution API 견적 신청'
        },
        {
          path : '/solution_api_detail',
          label: 'Solution API 견적 신청 상세'
        },
        {
          path: '/service_inquiry_done',
          label: '주문제작 견적 신청 완료'
        },
        {
          path: '/post',
          label: '블로그'
        },
        {
          path: '/post/detail',
          label: '블로그 상세'
        },
        {
          path: '/hosting/intro',
          label: 'AWS 서버 호스팅 소개'
        },
        {
          path: '/care_service/intro',
          label: '런치팩 케어서비스 소개'
        },
        {
          path: '/reg_bill_success',
          label: '빌링 등록 완료'
        },
      ];
      if(label === undefined) {
        label = list.filter(i=>{ return i.path === path });
        if(label.length>0) {
          label = label[0].label;
        } else {
          label = '';
        }
      }

      if(category === undefined) {
        category = list.filter(i=>{ return i.path === this.$route.fullPath });
        if(category.length>0) {
          category = category[0].label;
        } else {
          category = '';
        }
      }

      if(action === undefined) {
        action = '클릭';
      }

      console.log('route ga', category, label);
      if(category !== '' && label !== '') {
        this.setGa(category, action, label);
      }
    }
  }
})
// ga계정 : support@launchpack.co.kr에서 확인
Vue.use(VueGtag, {
  config: {
    id: 'G-4JTV7QPSVF'
  },
}, router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})
