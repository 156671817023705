<template>
  <transition name="fade">
    <div class="lp-toast" v-show="vToast">{{ msg }}</div>
  </transition>
</template>

<script>
  export default {
    name: "LpToast",
    created() {
      this.$root.$on('toast', res => {
        this.msg = res;
        this.vToast = true;
        setTimeout(() => {
          this.vToast = false;
        },1700);
      });
    },
    data() {
      return {
        vToast: false,
        msg: '',
      }
    }
  }
</script>

<style scoped>
  .lp-toast {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    width: 60%;
    max-width: 600px;
    padding: 12px;
    font-size: 0.9rem;
    color: white;
    background-color: rgba(0,0,0,0.8);
    text-align: center;
    border-radius: 4px;
    transform: translate(-50%, -50%);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }


</style>
